import Menu from './Menu'
import Modal, {
  ActiveModalProps,
  ModalConfig,
  MODAL_MIXINS,
  RecursiveOmitSetActiveModalId
} from 'shared/library/molecules/Modal'
import styled from '@emotion/styled'
import Button from 'library/atoms/Button'
import Icon from 'shared/library/atoms/Icon'
import { StyledHeader, StyledHeaderIconContainer } from './EolaHeader'
import useTranslation from 'next-translate/useTranslation'
import { generateModalTitleAuth } from '../ModalsAuth'
import { StyledLayout } from 'library/atoms/Layout'

export enum MODAL_IDS {
  menu = 'menu',
  basket = 'basket',
  signIn = 'headerSignIn',
  authenticated = 'headerAuthenticated',
  signOut = 'headerSignOut',
  signUp = 'headerSignUp'
}

export type ModalProps = RecursiveOmitSetActiveModalId<{
  [MODAL_IDS.menu]: any
}>

export const MODAL_CONFIG: ModalConfig = {
  [MODAL_IDS.menu]: {
    id: MODAL_IDS.menu,
    Content: Menu,
    clickOutsideToClose: true
  }
}

/**
 * Please refer to https://www.notion.so/eola/Modals-new-widget-d5a5b2c100c840878761cd28bb3279a2
 * for an explanation on our approach to Modals
 */
const HeaderModals = ({
  activeModal,
  setActiveModalId,
  ...props
}: Omit<ActiveModalProps, 'activeModalId'>) => {
  const { t } = useTranslation()

  const MODAL_TITLE = {
    [MODAL_IDS.menu]: t('common:menu'),
    ...generateModalTitleAuth(t)
  }

  const ModalContent = activeModal?.Content ? activeModal?.Content : () => null

  const ModalTitle = activeModal?.id
    ? MODAL_TITLE[activeModal?.id as keyof typeof MODAL_TITLE]
    : undefined

  const ModalOnClickOutside = activeModal?.clickOutsideToClose
    ? () => setActiveModalId(null)
    : undefined

  return (
    <StyledModal
      isOpen={!!activeModal}
      activeModalId={activeModal?.id}
      animationVariant="rightToLeft"
      fullWindow
      modalTitle={ModalTitle}
      onClickOutside={ModalOnClickOutside}
      setActiveModalId={setActiveModalId}
    >
      <StyledHeader>
        <StyledHeaderIconContainer>
          <Button
            aria-label={t('common:close')}
            variant="icon"
            onClick={() => setActiveModalId(null)}
          >
            <Icon size={24} name="close" color="grey" />
          </Button>
        </StyledHeaderIconContainer>
      </StyledHeader>
      <ModalContent {...props} setActiveModalId={setActiveModalId} />
    </StyledModal>
  )
}

export default HeaderModals

const StyledModal = styled(Modal)(
  ({ theme }) => ({
    ...MODAL_MIXINS.stickToRight(),
    margin: '0px !important',

    '[role="dialog"]': {
      background: theme.colors.background.secondary,
      paddingTop: '24px'
    }
  }),
  ({ theme, activeModalId }) =>
    activeModalId === 'menu'
      ? {
          '[role="dialog"]': {
            right: 0,
            margin: 0,
            maxWidth: theme.contentMaxWidth.xs,
            main: {
              padding: `${theme.space[6]}px ${theme.space[4]}px`
            },
            [theme.mediaQueries.widget]: {
              /**
               * Use the max-width of the widget as a reference to position the element
               * instead of letting it be fixed to the right side of the window.
               */
              right: `calc(50vw - ${theme.contentMaxWidth.xl / 2}px)`
            }
          }
        }
      : {
          '[role="dialog"]': {
            margin: 'auto',
            [theme.mediaQueries.widget]: {
              [`${StyledLayout}`]: {
                border: 'none'
              }
            }
          }
        }
)
