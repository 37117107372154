import React from 'react'
import styled from '@emotion/styled'
import Button from 'library/atoms/Button'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { LEGACY_EOLA_ROUTES, EOLA_ROUTES } from 'enums/routes'
import Icon from 'shared/library/atoms/Icon'
import usePageRouter from 'shared/state/usePageRouter'
import HeaderModals, {
  ModalProps,
  MODAL_CONFIG,
  MODAL_IDS
} from './HeaderModals'
import useModal from 'shared/state/useModal'
import { popularLocations } from 'enums/marketplaceNavigation'

const EolaHeader = () => {
  const { t } = useTranslation('common')
  const { setActiveModalId } = usePageRouter()

  return (
    <>
      <StyledHeader>
        <Link href={EOLA_ROUTES.HOME.url()} passHref legacyBehavior>
          <StyledLink>
            <Icon name="logo" color="darkGrey" height={26} width={70} />
          </StyledLink>
        </Link>
        <StyledNav>
          <MobileButton
            variant="icon"
            aria-label={t('common:menu')}
            onClick={() => setActiveModalId(MODAL_IDS.menu)}
          >
            <Icon size={24} name="menu" />
          </MobileButton>

          <DesktopNav>
            <DropDownLink>
              <StyledLinkForDropdown>Search by location</StyledLinkForDropdown>
              <DropDown>
                <DropDownHeading>Popular</DropDownHeading>
                {popularLocations.map(location => (
                  <DropDownItem key={location.slug}>
                    <Link
                      href={EOLA_ROUTES.LOCATION.url({
                        location: location.slug
                      })}
                    >
                      {location.name}
                    </Link>
                  </DropDownItem>
                ))}
                <DropDownItemSecondary>
                  <Link href={EOLA_ROUTES.LOCATIONS.url()}>
                    View all locations
                  </Link>
                </DropDownItemSecondary>
              </DropDown>
            </DropDownLink>

            <StyledLink href={EOLA_ROUTES.SEARCH.url()}>
              Search by activity
            </StyledLink>
            <StyledLink href={LEGACY_EOLA_ROUTES.BLOG.url()}>
              {t('blog')}
            </StyledLink>
            <StyledLink href={LEGACY_EOLA_ROUTES.ENTHUSIASTS_SIGN_IN.url()}>
              {t('sign-in')}
            </StyledLink>
            <StyledButton
              as="a"
              href={EOLA_ROUTES.MARKETPLACE.url()}
              variant="eola-primary"
            >
              Find activities
            </StyledButton>
            <StyledLink href={LEGACY_EOLA_ROUTES.BUSINESS_HOME.url()}>
              eola for business
            </StyledLink>
          </DesktopNav>
        </StyledNav>
      </StyledHeader>
      {<HeaderModalsRenderer />}
    </>
  )
}

export default EolaHeader

/**
 * Calls useModal and renders HeaderModals.
 * Isolated in its own component to assist conditional rendering.
 * Prevents error for when query falls over and data not available.
 */
const HeaderModalsRenderer = () => {
  const { activeModal, setActiveModalId, currentModalProps } =
    useModal<ModalProps>({
      MODAL_CONFIG
    })

  return (
    <HeaderModals
      activeModal={activeModal}
      setActiveModalId={setActiveModalId}
      {...currentModalProps}
    />
  )
}

const MobileButton = styled(Button)(() => ({
  display: 'block',

  '@media screen and (min-width: 1080px)': {
    display: 'none'
  }
}))

const DesktopNav = styled.div(() => ({
  display: 'none',

  '@media screen and (min-width: 1080px)': {
    display: 'Flex'
  }
}))

export const StyledHeader = styled.header(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.mixins.pxSpread([0, theme.space[5]]),
  marginBottom: theme.space[3],
  [theme.mediaQueries.md]: {
    marginBottom: theme.space[4],
    padding: 0
  }
}))

export const StyledHeaderIconContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flex: 1,
  '> *': {
    marginLeft: theme.space[3]
  },
  [theme.mediaQueries.sm]: {
    '> *': {
      marginLeft: theme.space[5]
    }
  }
}))

const DropDown = styled.div(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  display: 'block',
  flexDirection: 'column',
  gap: theme.space[2],
  padding: theme.mixins.pxSpread([theme.space[2], theme.space[3]]),
  borderRadius: theme.radii[1],
  backgroundColor: theme.colors.white,
  zIndex: 100
}))

const DropDownHeading = styled.div(({ theme }) => ({
  fontWeight: theme.fontWeights.heading,
  padding: theme.mixins.pxSpread([theme.space[1], 0])
}))

const DropDownLink = styled.div({
  position: 'relative',

  '> div': {
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s ease, visibility 0.3s ease',
    transitionDelay: '0.3s, 1s'
  },

  '&:hover, &:focus': {
    '> div': {
      opacity: 1,
      height: 'auto',
      visibility: 'visible',
      transition: 'opacity 0.3s ease, visibility 0.05s ease'
    }
  }
})

const DropDownItem = styled.div(({ theme }) => ({
  color: theme.colors.text.secondary,
  padding: `2px 0`,
  '&:hover': {
    color: theme.colors.black
  }
}))

const DropDownItemSecondary = styled.div(({ theme }) => ({
  color: theme.colors.text.secondary,
  padding: `8px 0`,

  '&:hover': {
    color: theme.colors.black
  }
}))

const StyledNav = styled.nav(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.space[3]
}))

const StyledLink = styled.a<{
  desktopOnly?: boolean
}>(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.colors.text.secondary,
    padding: theme.mixins.pxSpread([theme.space[2], theme.space[3]]),
    ...theme.fontStyle.h6,
    ...theme.mixins.eola.link,
    '&:hover': {
      color: theme.colors.black
    }
  }),
  ({ desktopOnly, theme }) =>
    desktopOnly
      ? {
          display: 'none',
          [theme.mediaQueries.md]: {
            display: 'inline-block'
          }
        }
      : undefined
)

const StyledLinkForDropdown = styled(StyledLink)(() => ({
  cursor: 'default'
}))

// Special case for header
const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
  lineHeight: 1,
  padding: theme.mixins.pxSpread([theme.space[3], theme.space[5]]),
  margin: theme.mixins.pxSpread([0, theme.space[3]])
}))
