import React from 'react'
import Layout from 'library/atoms/Layout'
import styled from '@emotion/styled'
import { EOLA_ROUTES, LEGACY_EOLA_ROUTES } from 'enums/routes'
import Button from 'library/atoms/Button'
import Link from 'next/link'
import { popularLocations } from 'enums/marketplaceNavigation'

const Menu = () => {
  return (
    <StyledLayout>
      <StyledNav>
        <div>
          <StyledLink>Search by location</StyledLink>
          <DropDown>
            <DropDownHeading>Popular</DropDownHeading>
            {popularLocations.map(location => (
              <DropDownItem
                key={location.slug}
                href={EOLA_ROUTES.LOCATION.url({ location: location.slug })}
              >
                {location.name}
              </DropDownItem>
            ))}
            <DropDownItemSecondary>
              <Link href={EOLA_ROUTES.LOCATIONS.url()}>View all locations</Link>
            </DropDownItemSecondary>
          </DropDown>
        </div>

        <StyledLink href={EOLA_ROUTES.SEARCH.url()}>
          Search by activity
        </StyledLink>
        <StyledLink href={LEGACY_EOLA_ROUTES.BLOG.url()}>Blog</StyledLink>
        <StyledLink href={LEGACY_EOLA_ROUTES.ENTHUSIASTS_SIGN_IN.url()}>
          Sign In
        </StyledLink>
        <StyledLink href={LEGACY_EOLA_ROUTES.BLOG.url()}>
          eola for business
        </StyledLink>
        <StyledButton
          as="a"
          href={LEGACY_EOLA_ROUTES.BUSINESS_HOME.url()}
          variant="eola-primary"
        >
          Find activities
        </StyledButton>
      </StyledNav>
    </StyledLayout>
  )
}

export default Menu

const StyledNav = styled.nav(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.space[8],
  padding: theme.mixins.pxSpread([theme.space[6], theme.space[4]])
}))

const StyledLink = styled.a(({ theme }) => ({
  fontSize: '24px',
  textDecoration: 'none',
  '&:hover': {
    color: theme.colors.eola.greys[50]
  }
}))

const DropDown = styled.div(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space[4]
}))

const DropDownHeading = styled.h3(() => ({
  fontSize: '18px',
  marginBottom: 0
}))

const DropDownItem = styled.a(({ theme }) => ({
  textDecoration: 'none',
  '&:hover': {
    color: theme.colors.eola.greys[50]
  }
}))

const DropDownItemSecondary = styled.div(({ theme }) => ({
  padding: theme.mixins.pxSpread([theme.space[1], 0])
}))

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
  lineHeight: 1,
  padding: theme.mixins.pxSpread([theme.space[3], theme.space[5]])
}))

const StyledLayout = styled(Layout)(() => ({
  width: '100%'
}))
