export const popularLocations = [
  {
    name: 'London',
    slug: 'london'
  },
  {
    name: 'Southampton',
    slug: 'southampton'
  },
  {
    name: 'Cardiff',
    slug: 'cardiff'
  },
  {
    name: 'Edinburgh',
    slug: 'edinburgh'
  },
  {
    name: 'Porthcawl',
    slug: 'porthcawl'
  },
  {
    name: 'Cornwall',
    slug: 'cornwall'
  },
  {
    name: 'Devon',
    slug: 'devon'
  },
  {
    name: 'Essex',
    slug: 'essex'
  }
]

export const popularActivities = [
  {
    name: 'Paddleboarding',
    slug: 'paddleboard'
  },
  {
    name: 'Surfing',
    slug: 'fish'
  },
  {
    name: 'Kayaking',
    slug: 'kayak'
  },
  {
    name: "Slip 'n' Slide",
    slug: 'slide'
  },
  {
    name: 'Skiing',
    slug: 'ski'
  },
  {
    name: 'Snowboarding',
    slug: 'snowboard'
  }
]
