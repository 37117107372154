import styled from '@emotion/styled'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { LEGACY_EOLA_ROUTES, EOLA_ROUTES } from 'enums/routes'
import Icon from 'shared/library/atoms/Icon'
import {
  popularLocations,
  popularActivities
} from 'enums/marketplaceNavigation'

const EolaFooter = () => {
  const { t } = useTranslation('common')

  return (
    <StyledFooter>
      <StyledWrapper>
        <StyledFooterColumn>
          <StyledLink href={LEGACY_EOLA_ROUTES.BUSINESS_HOME.url()}>
            <StyledLogo name="logo" color="white" height={52} width={140} />
            <Strapline>Find your next experience</Strapline>
          </StyledLink>
        </StyledFooterColumn>
        <StyledFooterColumn>
          <StyledImage
            width={200}
            height={70}
            src={
              'https://res.cloudinary.com/eola/image/upload/v1665140992/eola/public_images/eola-footer.svg'
            }
          />
        </StyledFooterColumn>
      </StyledWrapper>
      <StyledWrapperLinks>
        <StyledFooterColumn>
          <StyledColumnTitle>
            <StyledHeadingLink href={EOLA_ROUTES.LOCATIONS.url()}>
              Search by location
            </StyledHeadingLink>
          </StyledColumnTitle>
          {popularLocations.map(location => (
            <StyledLink
              key={location.slug}
              href={EOLA_ROUTES.LOCATION.url({ location: location.slug })}
            >
              {location.name}
            </StyledLink>
          ))}
        </StyledFooterColumn>

        <StyledFooterColumn>
          <StyledColumnTitle>
            <StyledHeadingLink href={EOLA_ROUTES.SEARCH.url()}>
              Search by activity
            </StyledHeadingLink>
          </StyledColumnTitle>
          {popularActivities.map(activity => (
            <StyledLink
              key={activity.slug}
              href={EOLA_ROUTES.SEARCH.url({ query: activity.slug })}
            >
              {activity.name}
            </StyledLink>
          ))}
          <StyledColumnTitle>
            <StyledHeadingLink href={EOLA_ROUTES.SPOTLIGHTS.url()}>
              Search by centre
            </StyledHeadingLink>
          </StyledColumnTitle>
        </StyledFooterColumn>

        <StyledFooterColumnNoHeader>
          <StyledLink href={EOLA_ROUTES.ABOUT_US.url()}>About</StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.BLOG.url()}>
            {t('blog')}
          </StyledLink>
          <StyledLink href="mailto:hello@eola.co">Contact</StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.TERMS_CONDITIONS.url()}>
            T&Cs
          </StyledLink>
          <StyledLink href={LEGACY_EOLA_ROUTES.BUSINESS_HOME.url()}>
            eola for business
          </StyledLink>
        </StyledFooterColumnNoHeader>
      </StyledWrapperLinks>
    </StyledFooter>
  )
}

export default EolaFooter

const StyledFooter = styled.footer(({ theme }) => ({
  color: theme.colors.eola.greys[50],
  backgroundColor: theme.colors.black,
  padding: theme.space[6],
  [theme.mediaQueries.md]: {
    padding: theme.space[10]
  }
}))

const Strapline = styled.p(() => ({
  fontSize: '22px',
  color: '#FFF'
}))

const StyledLogo = styled(Icon)(({ theme }) => ({
  marginTop: theme.space[5]
}))

const StyledWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.space[6],
  ...theme.mixins.centeredContainer('xxxl'),
  '> div:not(:first-of-type)': {
    padding: theme.space[3],
    [theme.mediaQueries.md]: {
      padding: theme.space[0]
    }
  },
  [theme.mediaQueries.lg]: {
    flexDirection: 'row'
  }
}))

const StyledWrapperLinks = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space[1],
  ...theme.mixins.centeredContainer('xxxl'),

  [theme.mediaQueries.md]: {
    flexDirection: 'row',
    gap: theme.space[8]
  },
  [theme.mediaQueries.lg]: {
    flexDirection: 'row',
    gap: theme.space[9]
  },
  [theme.mediaQueries.xl]: {
    flexDirection: 'row',
    gap: theme.space[11]
  }
}))

const StyledFooterColumn = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space[2],
  [theme.mediaQueries.lg]: {
    textAlign: 'left',
    ':last-of-type': {
      textAlign: 'center'
    }
  }
}))

const StyledFooterColumnNoHeader = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space[2],
  marginTop: '30px'
}))

const StyledLink = styled.a(({ theme }) => ({
  color: theme.colors.text.tertiary,
  padding: theme.mixins.pxSpread([0, theme.space[3]]),
  ...theme.fontStyle.p3,
  ...theme.mixins.eola.link,
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'none',
    color: theme.colors.white
  }
}))

const StyledHeadingLink = styled.a(() => ({
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline'
  }
}))

const StyledColumnTitle = styled.h5(({ theme }) => ({
  color: theme.colors.eola.greys[50],
  padding: theme.mixins.pxSpread([theme.space[2], theme.space[3]]),
  marginBottom: theme.space[0],
  ...theme.fontStyle.h5
}))

const StyledImage = styled.img(({ theme }) => ({
  marginTop: theme.space[5],
  width: 200,
  height: 70,
  [theme.mediaQueries.lg]: {
    marginRight: 'auto'
  }
}))
